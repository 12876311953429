import { Tab } from '@headlessui/react';
import { Navigation, A11y, Controller } from 'swiper';

import type { NextPage } from 'next';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { ChangeEvent, FormEvent, Fragment, useState } from 'react';
import dynamic from 'next/dynamic';
const EMAIL_VALIDATION_REG_EXP = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);
const MAXIMUM_1000_CHARS = new RegExp(/^.{1,1000}$/);
import axios from 'axios';
import Link from 'next/link';
import GNOHeader from '../components/wherewego/Header';
import GNOFooter from '../components/wherewego/Footer';
import ArrowLeft from '../components/icons/ArrowLeft';

const BrowserOnly = dynamic(() => import('../components/shared/BrowserOnly'), {
  ssr: false,
});
const Home: NextPage = () => {
  return (
    <>
      <GNOHeader />
      <Banner />
      <CareerSwiper />
      <Testimonials />
      <ContactForm />
      <GNOFooter />
    </>
  );
};

export default Home;

const Banner = () => {
  return (
    <div className="md:h-[80vh] md:py-0 py-10">
      <div className="container relative h-full px-10 mx-auto">
        <div className="items-center h-full grid-cols-2 md:grid">
          <div className="flex flex-col justify-center h-full md:-mr-18">
            <h1 className="text-center  md:text-left text-darkBlue text-3xl lg:text-4.5xl  2xl:text-5xl leading-10 lg:leading-heading3 2xl:leading-heading font-bold pb-8">
              Greater New Orleans Career Guide
            </h1>
            <p className="text-2xl text-center md:pr-40 md:text-left leading-copy font-extralight">
              Explore the great careers our region has to offer and the
              education pathways to get there.
            </p>
            <div className="mx-auto my-8 md:mx-0">
              <Link
                className="rounded-button bg-orange hover:bg-white border-2 border-orange border-solid hover:border-ora text-white hover:text-orange min-w-button w-[222px] max-w-full block h-button font-medium flex items-center justify-center"
                href="/careerguide/quiz"
              >
                Take Our Short Quiz
              </Link>
            </div>
          </div>
          <div className="flex flex-col justify-center h-full xl:-ml-10">
            <img
              className="object-contain w-[90%]"
              src="https://res.cloudinary.com/wherewego/image/upload/v1657317389/WWG2000%20Site%20Photos/hl6bljfiwtgv3vabmonk.png"
              alt="Images works in the technology and skilled trades industries"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const Testimonials = () => {
  return (
    <div className="px-8 py-10 md:p-28">
      <div className="container mx-auto">
        <h2 className="pb-10 text-2xl font-semibold leading-10 text-center md:text-4xl text-darkBlue">
          Career Path Testimonials
        </h2>
        <div className="w-[700px] mx-auto max-w-full">
          <Tab.Group>
            <Tab.List className="flex flex-col justify-between px-8 pb-10 md:flex-row md:px-0">
              <Tab as={Fragment}>
                {({ selected }) => {
                  return (
                    <button
                      className={`${
                        selected
                          ? 'border-orange text-orange'
                          : 'border-transparent text-darkBlue '
                      } font-semibold outline-none border-5 border-dashed rounded-button px-4 py-2 my-2`}
                    >
                      Jessica: Graphic Designer
                    </button>
                  );
                }}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => {
                  return (
                    <button
                      className={`${
                        selected
                          ? 'border-orange text-orange'
                          : 'border-transparent text-darkBlue '
                      } font-semibold outline-none border-5 border-dashed rounded-button px-4 py-2 my-2`}
                    >
                      Richard: Welder
                    </button>
                  );
                }}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => {
                  return (
                    <button
                      className={`${
                        selected
                          ? 'border-orange text-orange'
                          : 'border-transparent text-darkBlue '
                      } font-semibold outline-none border-5 border-dashed rounded-button px-4 py-2 my-2`}
                    >
                      Tessa: Accountant
                    </button>
                  );
                }}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="relative px-6 py-10">
                    <img
                      src="images/small-green-dotted@2x.png"
                      className="absolute bottom-0 left-0 z-0 w-32"
                    />
                    <img
                      className="z-10 relative h-[434px] object-cover rounded-testimonialsImage"
                      src="images/jessica@2x.png"
                      alt="Jessica: Graphic Designer"
                    />
                  </div>
                  <div className="flex flex-col items-center p-10">
                    <h3 className="text-4xl font-medium text-darkBlue">2020</h3>
                    <p className="text-lg font-semibold text-center text-darkBlue">
                      Tulane Graduate
                    </p>
                    <img className="h-16 my-2" src="images/down-arrow.png" />
                    <h3 className="text-4xl font-medium text-darkBlue">2021</h3>
                    <p className="text-lg font-semibold text-center text-darkBlue">
                      Ad Agency Internship
                    </p>
                    <img className="h-16 my-2" src="images/down-arrow.png" />
                    <h3 className="text-4xl font-medium text-darkBlue">2022</h3>
                    <p className="text-lg font-semibold text-center text-darkBlue">
                      Full Time Graphic Designer
                    </p>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="relative px-6 py-10">
                    <img
                      src="images/small-green-dotted@2x.png"
                      className="absolute bottom-0 left-0 z-0 w-32"
                    />
                    <img
                      className="z-10 relative h-[434px] object-cover rounded-testimonialsImage"
                      src="https://res.cloudinary.com/wherewego/image/upload/v1659116482/WWG2000%20Site%20Photos/owmjeefomvzdb1nl2cj4.jpg"
                      alt="Richard: Welder"
                    />
                  </div>
                  <div className="flex flex-col items-center p-10">
                    <h3 className="text-4xl font-medium text-darkBlue">2019</h3>
                    <p className="text-lg font-semibold text-center text-darkBlue">
                      Nunez Community College Graduate
                    </p>
                    <img className="h-16 my-2" src="images/down-arrow.png" />
                    <h3 className="text-4xl font-medium text-darkBlue">2021</h3>
                    <p className="text-lg font-semibold text-center text-darkBlue">
                      Welding Apprenticeship
                    </p>
                    <img className="h-16 my-2" src="images/down-arrow.png" />
                    <h3 className="text-4xl font-medium text-darkBlue">2022</h3>
                    <p className="text-lg font-semibold text-center text-darkBlue">
                      Full Time Welding Professional & Company owner
                    </p>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="relative px-6 py-10">
                    <img
                      src="images/small-green-dotted@2x.png"
                      className="absolute bottom-0 left-0 z-0 w-32"
                    />
                    <img
                      className="z-10 relative h-[434px] object-cover rounded-testimonialsImage"
                      src="https://res.cloudinary.com/wherewego/image/upload/v1659116483/WWG2000%20Site%20Photos/w8quh27lrjue8wijecwg.jpg"
                      alt="Tessa: Accountant"
                    />
                  </div>
                  <div className="flex flex-col items-center p-10">
                    <h3 className="text-4xl font-medium text-darkBlue">2018</h3>
                    <p className="text-lg font-semibold text-center text-darkBlue">
                      UNO Graduate
                    </p>
                    <img className="h-16 my-2" src="images/down-arrow.png" />
                    <h3 className="text-4xl font-medium text-darkBlue">2019</h3>
                    <p className="text-lg font-semibold text-center text-darkBlue">
                      Hancock Whitney Entry Level Accountant
                    </p>
                    <img className="h-16 my-2" src="images/down-arrow.png" />
                    <h3 className="text-4xl font-medium text-darkBlue">2022</h3>
                    <p className="text-lg font-semibold text-center text-darkBlue">
                      EY Manager
                    </p>
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <Link
          className="my-4 md:my-10 mx-auto rounded-button bg-orange hover:bg-white text-white hover:text-orange border-2 border-solid border-orange min-w-button w-[267px] h-button font-medium max-w-full flex items-center justify-center"
          href="/careerguide"
        >
          Explore Careers
        </Link>
        <div className="pt-24">
          <h2 className="pb-10 my-6 text-2xl font-semibold leading-10 text-center md:text-4xl text-darkBlue">
            Services to help in your education and training journey
          </h2>
          <div className="grid grid-rows-2 grid-cols-2 md:grid-rows-1 md:grid-cols-3 gap-8 max-w-full w-[1000px] mx-auto">
            <div className="flex flex-col items-center justify-between h-full">
              <img
                className="md:w-40 w-28"
                src="images/career-counseling@2x.png"
                alt="Career Counseling"
              />
              <h3 className="py-6 text-base font-bold text-center text-darkBlue md:text-2xl">
                Career Counseling
              </h3>
            </div>
            <div className="flex flex-col items-center justify-between h-full">
              <img
                className="md:w-40 w-28"
                src="images/childcare@2x.png"
                alt="Career Counseling"
              />
              <h3 className="py-6 text-base font-bold text-center text-darkBlue md:text-2xl">
                Childcare
              </h3>
            </div>
            <div className="flex flex-col items-center justify-between h-full col-span-2 md:col-auto md:row-auto">
              <img
                className="md:w-40 w-28"
                src="images/other-support@2x.png"
                alt="Career Counseling"
              />
              <h3 className="py-6 text-base font-bold text-center text-darkBlue md:text-2xl">
                Other Support
              </h3>
            </div>
          </div>
          <Link
            href="https://gnoresourcehub.wherewego.org"
            target="_blank"
            className="my-10 mx-auto rounded-button bg-orange hover:bg-white text-white hover:text-orange border-2 border-solid border-orange max-w-full min-w-button w-[267px] flex items-center justify-center h-button font-medium"
          >
            Checkout Our Resource Hub
          </Link>
        </div>
      </div>
    </div>
  );
};

const CareerSwiper = () => {
  const pathwaysNextRef = React.useRef<HTMLDivElement>(null);
  const pathwaysPrevRef = React.useRef<HTMLDivElement>(null);
  const [swiper, setSwiper] = useState<any | null>(null);
  return (
    <div className="px-8 py-16 bg-darkBlue md:p-28">
      <div className="container mx-auto">
        <div className="flex flex-col-reverse gap-8 lg:grid lg:grid-cols-2 lg:gap-0">
          <div className="relative py-10 md:py-0 md:pr-10">
            <img
              className="absolute top-0 left-0 h-[73%] w-3/4 object-contain"
              src="images/blue-dotted-background@2x.png"
            />
            <BrowserOnly>
              <Swiper
                direction="horizontal"
                slidesPerView={1}
                modules={[Navigation, A11y, Controller]}
                navigation={{
                  nextEl: pathwaysNextRef?.current,
                  prevEl: pathwaysPrevRef?.current,
                }}
                onSwiper={(swiper: any) => setSwiper(swiper)}
                loop={true}
              >
                <SwiperSlide>
                  <img src="images/tulane-litigation@2x.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="images/nocc-electrician@2x.png" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="images/uno-business@2x.png" />
                </SwiperSlide>
                <div className="flex items-center my-6 navigation md:my-0">
                  <div
                    onClick={() => {
                      if (!swiper) return;
                      swiper.slidePrev();
                    }}
                    className="p-5 mr-2 border-2 border-solid cursor-pointer prev bg-orange hover:bg-white stroke-white hover:stroke-orange border-orange rounded-button"
                  >
                    <ArrowLeft className="stroke-inherit h-7 w-7" />
                  </div>
                  <div
                    onClick={() => {
                      if (!swiper) return;
                      swiper.slideNext();
                    }}
                    ref={pathwaysNextRef}
                    className="p-5 mr-2 border-2 border-solid cursor-pointer next bg-orange hover:bg-white stroke-white hover:stroke-orange border-orange rounded-button"
                  >
                    <ArrowLeft className="rotate-180 stroke-inherit h-7 w-7" />
                  </div>
                </div>
              </Swiper>
            </BrowserOnly>
          </div>
          <div className="flex flex-col justify-center w-full lg:pl-10">
            <h2 className="pb-6 text-3xl font-medium leading-10 text-white md:text-4xl md:leading-heading3 xl:leading-heading2">
              Great Careers. <br /> Straightforward pathways.
            </h2>
            <p className="pb-6 text-lg font-thin text-white">
              Finding a great career can be complicated. Whether you are a high
              school student, parent of a student, counselor, or in the middle
              of your career looking for a change, use this site to check out
              what it takes to enter some of the fastest growing careers in the
              New Orleans region.
            </p>
            <div className="flex flex-col md:flex-row">
              <Link
                className="flex items-center justify-center block w-auto mx-8 my-2 font-medium text-white border-2 border-solid rounded-button bg-orange hover:bg-white hover:text-orange border-orange min-w-button h-button md:mr-2"
                href="https://gno.wherewego.org/careerguide/quiz"
              >
                Take Our Short Quiz
              </Link>
              <Link
                className="flex items-center justify-center block w-auto mx-8 my-2 font-medium text-white border-2 border-solid rounded-button bg-orange hover:bg-white hover:text-orange border-orange exp min-w-button h-button md:ml-2"
                href="https://gno.wherewego.org/careerguide"
              >
                See All Careers
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactForm = () => {
  const [formResponse, setFormResponse] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const [formState, setFormState] = useState({
    name: {
      value: '',
      required: true,
      error: false,
      pattern: false,
      errorMessage: 'This field is required',
    },
    email: {
      value: '',
      required: true,
      pattern: EMAIL_VALIDATION_REG_EXP,
      error: false,
      errorMessage: 'Please provider a valid email',
    },
    message: {
      value: '',
      required: true,
      error: false,
      pattern: MAXIMUM_1000_CHARS,
      errorMessage: 'Please limit to 1000 characters',
    },
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = validateForm();
    if (!isValid) return;
    const data = {
      name: formState.name.value,
      email: formState.email.value,
      message: formState.message.value,
    };
    const response = await axios
      .post('/gno-api/email', data)
      .then((res) => res.data)
      .catch((err) => err.response.data);

    setFormResponse(response);
  };

  const validateForm = (): boolean => {
    const fields = Object.keys(formState) as ('name' | 'email' | 'message')[];
    let isValid = true;
    fields.forEach((field) => {
      if (formState[field].required && !formState[field].value) {
        formState[field].error = true;
        formState[field].errorMessage = 'This field is required.';
        isValid = false;
      } else if (
        Boolean(formState[field].pattern) &&
        !(formState[field].pattern as RegExp).test(formState[field].value)
      ) {
        formState[field].error = true;
        formState[field].errorMessage =
          field === 'message'
            ? 'Please limit to 1000 characters.'
            : `Please provide a valid ${field}`;
        isValid = false;
      } else {
        formState[field].error = false;
      }
    });
    setFormState({ ...formState });

    return isValid;
  };

  return (
    <div className="container mx-auto my-10 md:my-10">
      <div className="relative flex flex-col lg:grid grid-rows-contact grid-cols-contact">
        <div className="flex flex-col col-start-1 col-end-3 grid-rows-1 row-start-2 row-end-3 px-6 pt-6 pb-0 mx-6 bg-lightBlue lg:py-8 lg:px-10 2xl:p-20 rounded-2xl lg:grid grid-cols-contact">
          <div className="lg:pr-6">
            <h2 className="text-2xl font-semibold text-white 2xl:text-4xl">
              Want to learn more about the Career Guide? Get in touch.
            </h2>
            {formResponse && (
              <p
                className={`${
                  formResponse.error
                    ? 'bg-red/50 text-white'
                    : 'bg-white text-lightBlue'
                } mt-6 p-4 font-semibold`}
              >
                {formResponse.message}
              </p>
            )}
            <form className="py-4 pb-10 2xl:py-8" onSubmit={handleSubmit}>
              <input
                value={formState.name.value}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  formState.name.value = event.target.value;
                  setFormState({ ...formState });
                }}
                type="text"
                className={`rounded-lg p-4 2xl:p-6 text-white w-full font-medium outline-none placeholder:text-white/50 my-2
                ${formState.name.error ? 'bg-red/50' : 'bg-mdBlue'}
                `}
                placeholder="Your Name"
              />
              {formState.name.error && (
                <p className="text-sm font-semibold text-red/50">
                  {formState.name.errorMessage}
                </p>
              )}
              <input
                value={formState.email.value}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  formState.email.value = event.target.value;
                  setFormState({ ...formState });
                }}
                type="text"
                className={`rounded-lg p-4 2xl:p-6 text-white w-full font-medium outline-none placeholder:text-white/50 my-2
                ${formState.email.error ? 'bg-red/50' : 'bg-mdBlue'}
                `}
                placeholder="Your Email"
              />
              {formState.email.error && (
                <p className="text-sm font-semibold text-red/50">
                  {formState.email.errorMessage}
                </p>
              )}
              <textarea
                value={formState.message.value}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  formState.message.value = event.target.value;
                  setFormState({ ...formState });
                }}
                className={`rounded-lg p-4 2xl:p-6 text-white w-full font-medium outline-none placeholder:text-white/50 my-2
                ${formState.message.error ? 'bg-red/50' : 'bg-mdBlue'}
                `}
                placeholder="Message"
                rows={8}
              ></textarea>
              {formState.message.error && (
                <p className="text-sm font-semibold text-red/50">
                  {formState.message.errorMessage}
                </p>
              )}
              <button
                type="submit"
                className="w-full p-6 my-4 text-lg font-semibold bg-white border-2 border-white border-solid hover:bg-lightBlue rounded-button text-lightBlue hover:text-white hover:border-white"
              >
                Submit
              </button>
            </form>
          </div>
          <div className="block w-auto col-start-2 col-end-3 row-start-1 row-end-3 px-10 lg:hidden">
            <img
              className="object-contain max-h-[500px] mx-auto"
              src="images/email-graphic@2x.png"
            />
          </div>
        </div>

        <div className="relative hidden w-auto w-full col-start-2 col-end-3 row-start-1 row-end-3 px-10 lg:block">
          <img
            className="absolute block object-contain w-5/6 xl:relative inset-4 xl:inset-0 xl:w-auto"
            src="images/email-graphic@2x.png"
          />
        </div>
      </div>
    </div>
  );
};
